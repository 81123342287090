<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Overzicht CV's" />

    <div class="bg-white shadow overflow-hidden sm:rounded-md mb-5">
      <div class="sm:grid sm:grid-cols-4 sm:items-start sm:py-5">
        <label for="search" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 px-6">
          Search
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <div class="max-w-lg flex rounded-md shadow-sm">
            <input type="text" name="search" id="search" autocomplete="search" class="flex-1 block w-full focus:ring-orange-500 focus:border-orange-500 min-w-0 rounded sm:text-sm border-gray-300" v-model="searchQuery" v-on:keyup.enter="search">
          </div>
        </div>
        <div class="block text-sm font-medium text-gray-700">
          <button class="px-4 py-2 md:ml-2 border border-gray-300 text-sm font-medium rounded-md" @click="search">search</button>
          <button class="px-4 py-2 md:ml-2 border border-gray-300 text-sm font-medium rounded-md" @click="clear">clear</button>
        </div>
      </div>
    </div>

    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mb-4" v-if="searchResults && searchResults.hits">
      <li v-for="hit in searchResults.hits" :key="hit.objectID" class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1 truncate">
            <div class="flex items-center space-x-3">
              <h3 class="text-gray-900 text-sm font-medium truncate" @click="reindex(hit.objectID.replace('user_', ''))">{{hit.first_name}} {{hit.last_name}}</h3>
              <span class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">{{hit.resumes.length}} {{hit.resumes.length > 1 ?'cv\'s':'cv'}}</span>
            </div>
            <p class="mt-1 text-gray-500 text-sm truncate">{{hit.email}}</p>
          </div>
          <DynamicImage :source="hit.photo" type="400x400x" class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" v-if="hit.photo" />
        </div>
        <div>
          <div class="-mt-px flex divide-x divide-gray-200">
            <div class="w-0 flex-1 flex">
              <a :href="`mailto:${hit.email}`" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                <Email32 class="w-5 h-5 text-gray-400" aria-hidden="true" />
                <span class="ml-3">Email</span>
              </a>
            </div>
            <div class="-ml-px w-0 flex-1 flex">
              <router-link :to="`/cv/${hit.objectID.replace('user_', '')}`" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                <Education32 class="w-5 h-5 text-gray-400" aria-hidden="true" />
                <span class="ml-3">CV Data</span>
              </router-link>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!--
    <div class="bg-white shadow overflow-hidden sm:rounded-md" v-if="searchResults && searchResults.hits">
      
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Klant CV's?
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Re-index
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="hit in searchResults.hits" :key="hit.objectID">
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <router-link :to="`/cv/cv_data/${hit.objectID.replace('user_', '')}`" class="text-orange-600 hover:text-orange-900">{{hit.first_name}}</router-link>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button @click="reindex(hit.objectID.replace('user_', ''))">{{hit.objectID.replace('user_', '')}}</button>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
    -->

    <Pagination v-if="searchResults && searchResults.hits && searchResults.nbPages > 1" :page="searchResults.page" :pages="searchResults.nbPages-1" :itemCount="searchResults.nbHits" :pageSize="searchResults.hitsPerPage" @next="next" @previous="previous" />

  </main>
</template>

<script>
import CvService from '../../../../services/admin/cv/service';
import Pagination from '../../../../components/Pagination';
import DynamicImage from '../../../../components/DynamicImage.vue'
import { Education32, Email32 } from '@carbon/icons-vue';

export default {
  name: 'Cvs',
  components: {
    Pagination,
    DynamicImage,
    Education32,
    Email32
  },
  data() {
    return {
      searchResults: null,
      searchQuery: '',
      page: 0,
      pageSize: 51
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      CvService.getAll(this.page, this.pageSize, this.searchQuery).then(
        response => {
          this.searchResults = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          if (error.response.status === 401)
            this.$router.push(`/auth`);
        }
      );
    },
    async reindex(userId) {
      await CvService.updateUserCv(userId);
    },
    photoUrl(key) {
      return `${process.env.VUE_APP_CLOUDFRONT_URL}${key}`
    },
    reset() {
      this.pages = 0;
      this.itemCount = 0;
      this.page = 0;
    },
    clear() {
      this.searchQuery = null;
      this.reset();
      this.getData();
    },
    search() {
      this.reset();
      this.getData();
    },
    next() {
      this.page = this.page + 1;
      this.getData();
    },
    previous() {
      this.page = this.page - 1;
      this.getData();
    }
  }
};
</script>
